import React from 'react';
import { RichText } from 'prismic-reactjs';

import {
  Grid, 
  Item, 
  Title,
  Section,
  SectionTitle,
  Container,
  Image,
  ImageContainer
} from './styles';

import { linkResolver } from '../../../../utils/linkResolver';

const ArticleGrid = ({ articles, limit, title, bgColor, small }) => {

  // Filter
  let filterArticles = [];
  if (articles && limit) {
    articles.forEach((item, index) => index < limit && filterArticles.push(item));
  } else {
    filterArticles = articles;
  }

  // Content
  let content; 
  if (filterArticles) {
    content = filterArticles.map((article, i)=> {
      const { title, featured_image } = article.data;
      let image;
      if (featured_image) {
        image = (
          <ImageContainer>
            <Image
              fluid={featured_image.fluid} 
              alt={featured_image.alt}
              small={small}
            />
          </ImageContainer>
        );
      }
      return (
        <Item
          key={'article-'+i} 
          to={linkResolver(article)}
          data-sal='slide-up'
          data-sal-easing='ease' 
          data-sal-duration='600'
        >
          {image}
          <Title>
            {RichText.asText(title.raw)}
          </Title>
        </Item>
      );
    });
  } else {
    content = (
      <p>
        No content found!
      </p>
    );
  }

  // Section title
  let sectionTitle;
  if (title) {
    sectionTitle = (
      <SectionTitle
        data-sal='slide-up'
        data-sal-easing='ease' 
        data-sal-duration='600'
      >
        {RichText.asText(title.raw)}
      </SectionTitle>
    );
  }

  return (
    <Section bgColor={bgColor}>
      <Container>
        {sectionTitle}
        <Grid small={small}>
          {content}
        </Grid>
      </Container>
    </Section>
  );
};

export default ArticleGrid;
