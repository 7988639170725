import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Subheader from '../components/page/SubHeader';
import ArticleGrid from '../components/page/Article/ArticleGrid';

const Latest = ({ data }) => {
  const articles = data.prismicArticles.data;
  const allArticles = data.allPrismicArticle;

  return (
    <Layout>
      <SEO 
        title={articles.meta_title}
        description={articles.meta_description}
        type='article'
      />
      <Subheader 
        title={articles.title}
        text={articles.description}
      />
      <ArticleGrid articles={allArticles.nodes} />
    </Layout>
  );
};

export default Latest;

export const query = graphql`
  query {
    prismicArticles {
      data {
        title {
          raw
        }
        meta_title
        meta_description
        description {
          raw
        }
      }
    }
    allPrismicArticle(sort: {order: DESC, fields: last_publication_date}) {
      nodes {
        data {
          title {
            raw
          }
          featured_image {
            alt
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        type
        uid
        lang
      }
    }
  }
`;